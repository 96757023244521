<template>
    <div class="job-fair-desc user-content ">
        <div class="zph_show">
            <div class="pic">
                <!-- <img :src="img" alt="" srcset=""> -->
                <img :src="jobDetail.url" alt="">
            </div>
            <div class="info">
                <div class="title">{{ jobDetail.jobName }}</div>
                <div class="desc">
                    <div class="organizers"><i class="el-icon-reading iconstyle1"></i> 主办方: {{ jobDetail.organizer }}</div>
                    <div class="organizers"><i class="el-icon-time iconstyle1"></i> 举办时间: {{ jobDetail.holdStartTime }} 至 {{ jobDetail.holdEndTime }}</div>
                    <div class="organizers"><i class="el-icon-position iconstyle1"></i> 举办会场: {{ jobDetail.holdField }}</div>
                    <div class="organizers"><i class="el-icon-info iconstyle1"></i> 公交：{{ jobDetail.trafficRoutes }}</div>
                </div>
                <div class="open-tel">
                    <div class="tel-name">联系<br />电话</div>
                    <div class="tel-phone">{{ jobDetail.contactsPhone }}</div>
                    <div class="tel-n">( {{ jobDetail.contacts }} )</div>
                </div>
            </div>
        </div>
        <div class="company-list-warpper" style="margin-bottom:20px;">
            <div class="company-title">
                <div class="title-text">参会企业</div>
            </div>
            <!-- {{companyList}} -->
            <div class="company-list" v-if="companyList.length>0">
                <div class="company-item flex" @click="openCompanyDetails(item)" v-for="(item, index) in companyList" :key="`acompany${index}`">
                    <div class="company-logo">
                        <img :src="item.logo||require('../../../assets/img/users/company_logo.png')" alt="">
                    </div>
                    <div class="company-base flex text-overflow">
                        <div class="c-base-warpper">
                            <div class="c-base-name text-overflow">{{item.firm}}</div>
                            <div class="c-base-info"><span class="base-gray">展位号：</span>{{item.regionName}} - {{item.seatNum}}</div>
                        </div>
                        <div class="c-base-job text-overflow flex">
                            <span class="base-gray">招聘职位：</span>
                            <div class="j-list">
                                <span class="j-list-item" v-for="(row, index1) in item.jobs" :key="`jobs${index1}`" @click="routeToJobDetail(row)">{{row.jobName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-list flex" v-else>
            <img src="../../../assets/img/users/noJobTip.png" alt="" srcset="">
        </div>
        </div>
    </div>
</template>
<script>
import userRequest from '../../../api/user'
export default {
    mounted() {
        let query = this.$route.query
        query.fairId && (this.infoForm.id = query.fairId)
        this.getUserJobFairInfo()
    },
    data() {
        return {
            jobDetail: {}, // 详情基本信息
            companyList: [], //参会企业
            infoForm: {
                id: '',
                pageNo: 1,
                pageSize: 10
            }
        }
    },
    methods: {
        getUserJobFairInfo() {
            userRequest.getUserJobFairInfo(this.infoForm).then((res) => {
                this.jobDetail = res.data.jobFairPojo
                let companyList = res.data.companyAndLocation
                if (companyList.length > 0) {
                    companyList.forEach((item) => {
                        item.jobsplit = item.positionNameAndNumber.split(',')
                        item.positionIds = item.positionId.split(',')
                        item.jobs = item.jobsplit.map((row, index) => {
                            let arr = row.split(':')
                            return {
                                jobName: arr[0],
                                jobId: item.positionIds[index]
                            }
                        })
                    })
                }
                console.log('companyList', companyList)
                this.companyList = companyList
            })
        },
        routeToJobDetail(row) {
            this.$router.push({
                path: '/user/jobDetail',
                query: {
                    workId: row.jobId,
                    workName: row.jobName
                }
            })
        },
        openCompanyDetails(item) {
            // console.log(item)
            // return
            this.$router.push({
                path: '/user/companyNews/gongsi',
                query: {
                    companyId: item.memberId
                }
            })
        },
        routeToCompanyNews(item) {
            this.$router.push({
                path: '/user/companyNews',
                query: {
                    companyId: item.id 
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .base-gray{
        color: #999;
    }
    .job-fair-desc{
        margin-top: 20px;
            .zph_show{
                padding: 20px 0 20px 30px;
                display: flex;
                margin-bottom: 20px;
                background: #fff;
            .pic{
                width: 320px;
                height: 212px;
                margin-right: 30px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .info{
                width: 50%;
                .title{
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 46px;
                    margin-bottom: 10px;
                }
                .desc{
                    margin-bottom: 10px;
                    .organizers{
                        padding: 3px 0;
                        color: #666;
                    }
                }
                .open-tel{
                    display: flex;
                    align-items: center;
                    min-width: 410px;
                    width: 50%;
                    padding: 5px 20px;
                    border: 2px solid $main;
                    .tel-name{
                        line-height: 18px;
                        padding-right: 9px;
                        border-right: 1px solid #eee;
                    }
                    .tel-phone{
                        margin-left: 20px;
                        color: $main;
                        font-size: 28px;
                        font-weight: 700;
                        letter-spacing: 2px;
                    }
                    .tel-n{
                        margin-left: 7px;
                        color: #666;
                    }
                }
            }
        }
        .company-list-warpper{
            padding: 10px 0;
            min-height: 600px;
            background: #fff;
            .company-title{
                padding: 8px 0;
                border-bottom: 1px solid #eee;
                .title-text{
                    position: relative;
                    padding-left: 20px;
                    font-size: 18px;
                    &::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        width: 3px;
                        height: 20px;
                        background: $main;
                    }
                }
            }
            .company-list{
                .company-item{
                    user-select: none;
                    padding: 20px;
                    border: 1px solid #f2f2f2;
                    .company-logo{
                        width: 92px;
                        height: 92px;
                        overflow: hidden;
                        border: 1px solid #f2f2f2;
                        img{
                            width: 100%;
                        }
                    }
                    .company-base{
                        flex: 1;
                        padding: 10px 0;
                        margin-left: 20px;
                        flex-direction: column;
                        justify-content: space-between;
                        .c-base-name{
                            font-size: 16px;
                            cursor: pointer;
                            color: $hover;
                            &:hover{
                                color: $main;
                            }
                        }
                        .c-base-info{
                            width: 100%;
                            margin-top: 10px;
                        }
                        .j-list{
                           .j-list-item{
                               color: #333;
                               cursor: pointer;
                               margin-right: 15px;
                               &:hover{
                                   color: $main;
                               }
                           } 
                        }
                    }
                }  
            }
        }
        .no-list{
            margin: 100px 0 200px 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
    }
</style>